
<div style="display: inline-block">
  <Dropdown @on-click="handleLanDropdown">
    <div class="header-right-icon header-action">
      <Icon type="md-globe" :size="20" class="language"></Icon>
    </div>
    <DropdownMenu slot="list">
      <DropdownItem name="zh-CN" :selected="currLang == 'zh-CN'"
        ><div class="lan-logo-content">
          <img :src="public_path + 'icon/chinese.png'" class="country-logo" />简体中文
        </div></DropdownItem
      >
      <DropdownItem name="en-US" :selected="currLang == 'en-US'"
        ><div class="lan-logo-content">
          <img :src="public_path + 'icon/english.png'" class="country-logo" />English
        </div></DropdownItem
      >
    </DropdownMenu>
  </Dropdown>
</div>
