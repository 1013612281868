export const SearchComparator = [{
        key: "等于",
        value: "==",
        type: ["string", "number", "time", "select"]
    },
    {
        key: "不等于",
        value: "!=",
        type: ["string", "number", "time", "select"]
    },
    {
        key: "大于",
        value: ">",
        type: ["number", "time"]
    },
    {
        key: "大于等于",
        value: ">=",
        type: ["number", "time"]
    },
    {
        key: "小于",
        value: "<",
        type: ["number", "time"]
    },
    {
        key: "小于等于",
        value: "<=",
        type: ["number", "time"]
    },
    {
        key: "包含",
        value: "like",
        type: ["string"]
    },
    {
        key: "不包含",
        value: "not like",
        type: ["string"]
    },
];

export const GetSearchComparator = function(valueType) {
    let result = [];
    SearchComparator.forEach((item)=>{        
        if (item.type.indexOf(valueType) > -1){
            result.push(item);
        }
    })
    return result
}