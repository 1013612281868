
<div class="set-password">
  <Poptip trigger="focus" placement="right" width="250">
    <Input
      type="password"
      password
      :maxlength="maxlength"
      v-model="currentValue"
      @on-change="handleChange"
      :size="size"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
    />
    <div :class="tipStyle" slot="content">
      <div class="words">强度 : {{strength}}</div>
      <Progress
        :percent="strengthValue"
        :status="progressStatus"
        hide-info
        style="margin: 13px 0;"
      />
      <br />请至少输入 6 个字符。请不要使
      <br />用容易被猜到的密码。
    </div>
  </Poptip>
</div>
