
<div
  @click="handleChange"
  v-if="showFullScreenBtn"
  style="display: inline-block"
>
  <Tooltip :content="value ? $t('exitFullscreen') : $t('fullscreen')" placement="bottom">
    <div class="header-right-icon header-action">
      <Icon :type="value ? 'md-contract' : 'md-expand'" :size="20"></Icon>
    </div>
  </Tooltip>
</div>
