import { getStore } from './storage';

// 用户角色判断，支持多条件判断，多个角色用半角逗号,分割
const hasRole = {
    install(Vue, options) {
        Vue.directive('hasRole', {
            inserted(el, binding) {
                let userRoleCount = 0;
                let roles = getStore("roles");
                let needRoles = binding.value.split(",");
                for (let i = 0; i < needRoles.length; i++) {
                    if (roles && roles.includes(needRoles[i])) {
                        userRoleCount++;
                    }
                }
                if (userRoleCount == 0) {
                    el.parentNode.removeChild(el);
                }
                // console.log(roles, needRoles, userRoleCount);
            }
        });
    }
};

export default hasRole;
