
<div class="main" :class="{ 'main-hide-text': shrink }">
  <!-- 左侧菜单 -->
  <div
    :class="`sidebar-menu-content side-bar-menu-theme-${menuTheme}`"
    :style="{
      width: shrink ? '60px' : menuWidth + 'px',
      overflow: shrink ? 'visible' : 'auto',
    }"
  >
    <shrinkable-menu
      :shrink="shrink"
      @on-change="handleSubmenuChange"
      :theme="menuTheme"
      :before-push="beforePush"
      :menu-list="menuList"
    >
      <div slot="top" class="logo-content" v-if="showLogo || fixNav">
        <img
          v-show="
            !shrink && (menuTheme != 'light' || mainTheme == 'darkMode')
          "
          :src="public_path+'logo-white.png'"
        />
        <img
          v-show="!shrink && menuTheme == 'light' && mainTheme != 'darkMode'"
          :src="public_path+'logo-black.png'"
        />
        <img v-show="shrink" :src="public_path+'logo-min.png'" key="min-logo" />
      </div>
    </shrinkable-menu>
  </div>
  <!-- 右上顶部导航条 -->
  <div
    :class="`main-header-content fix-nav-${fixNav}`"
    :style="{
      paddingLeft: navPaddingLeft,
    }"
  >
    <div :class="`main-header header-theme-${navTheme}`">
      <div class="header-left">
        <!-- 固定图标 -->
        <div v-if="fixNav && showLogo" style="width: 220px" class="fix-logo">
          <img
            :src="public_path+'logo-black.png'"
            v-if="navTheme == 'light' && mainTheme != 'darkMode'"
          />
          <img :src="public_path+'logo-white.png'" key="max-logo" v-else />
        </div>
        <!-- 收缩图标 -->
        <div
          class="header-navicon-content header-action"
          @click="toggleClick"
          v-if="showFold"
        >
          <Icon
            custom="iconfont icon-menu-unfold"
            size="20"
            v-show="this.shrink"
          />
          <Icon
            custom="iconfont icon-menu-fold"
            size="20"
            v-show="!this.shrink"
          />
        </div>
        <div class="header-middle-content">
          <!-- 顶部菜单 -->
          <div v-if="navType == 1">
            <navMenu
              :theme="navTheme"
              :currNav="currNav"
              :navList="navList"
              @on-click="selectNav"
              :sliceNum="sliceNum"
              :showIcon="showNavMenuIcon"
            />
          </div>
          <!-- 下拉菜单 -->
          <div v-if="navType == 2">
            <navApp
              currType="tabs"
              placement="bottom-start"
              :currNavTitle="currNavTitle"
              :currNav="currNav"
              :navList="navList"
              :type="navShowType"
              :theme="navTheme"
              @on-click="selectNav"
            />
          </div>
          <!-- 面包导航 -->
          <div class="main-breadcrumb" v-if="navType == 3">
            <breadcrumb-nav :theme="navTheme" :currentPath="currentPath" />
          </div>
        </div>
      </div>
      <!-- 顶部右侧按钮 -->
      <div class="header-right-content">
        <search :theme="navTheme" :type="searchType" v-if="showSearch" />
        <navApp
          :currNav="currNav"
          :navList="navList"
          :type="navShowType"
          @on-click="selectNav"
          v-if="navType == 3"
        />
        <full-screen v-model="isFullScreen" @on-change="fullscreenChange" />
        <language />
        <message-tip />
        <user />
        <theme />
      </div>
    </div>
  </div>
  <!-- Tags 标签 -->
  <div
    class="nav-tags"
    :style="{
      paddingLeft: shrink ? '60px' : menuWidth + 'px',
    }"
  >
    <tags-page-opened :pageTagsList="pageTagsList" v-if="showTags" />
  </div>
  <!-- 页面部分 -->
  <div
    class="single-page-content"
    :style="{
      left: shrink ? '60px' : menuWidth + 'px',
      top: showTags ? '100px' : '60px',
    }"
  >
    <div class="single-page">
      <keep-alive :include="cachePage">
        <router-view />
      </keep-alive>
    </div>
    <!-- 页面页脚 -->
    <!-- <div class="main-page-footer-content" v-if="showFooter">
      <Footer class="main-page-footer" />
    </div> -->
  </div>
  <!-- 全局加载动画 -->
  <circleLoading class="loading-position" v-show="loading" />
</div>
