
<div style="display: inline-block">
  <Dropdown transfer trigger="hover" @on-click="handleClickUserDropdown">
    <div style="display: inline-block">
      <div class="header-right-icon header-user header-action">
        <Avatar v-if="avatar" :src="avatar" size="small"></Avatar>
        <Avatar v-else icon="md-person" size="small"></Avatar>
        <span class="header-userName">{{ nickName }}</span>
      </div>
    </div>
    <DropdownMenu slot="list">
      <DropdownItem name="ownSpace">
        <Icon type="md-person" style="margin: 0 10px 0 0" />{{
          $t("userCenter")
        }}</DropdownItem
      >
      <DropdownItem name="changePass">
        <Icon type="md-unlock" style="margin: 0 10px 0 0" />{{
          $t("changePass")
        }}</DropdownItem
      >
      <DropdownItem name="loginout" divided>
        <Icon type="md-log-out" style="margin: 0 10px 0 0" />{{
          $t("logout")
        }}</DropdownItem
      >
    </DropdownMenu>
  </Dropdown>

  <!-- 修改密码 -->
  <changePass v-model="showChangePass" />
</div>
