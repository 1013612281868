import Cookies from 'js-cookie';

const user = {
    state: {
        nickName: "",
        avatar: "",
        departmentId: "",
        departmentTitle: "",
        myDepartmentIds: [],
    },
    mutations: {
        setUserInfo(state, v) {
            state.nickName = v.nickName;
            state.avatar = v.avatar;
            state.departmentId= v.departmentId,
            state.departmentTitle= v.departmentTitle,
            state.myDepartmentIds= v.myDepartmentIds,
            localStorage.nickName = v.nickName;
            localStorage.avatar = v.avatar;
            localStorage.departmentId = v.departmentId;
            localStorage.departmentTitle = v.departmentTitle;
            localStorage.myDepartmentIds =  v.myDepartmentIds;
        },
        setNickname(state, v) {
            state.nickName = v;
            localStorage.nickName = v;
        },
        setAvatar(state, v) {
            state.avatar = v;
            localStorage.avatar = v;
        },
        logout() {
            Cookies.remove('userInfo');
            // 清空打开的页面等数据 保留语言、主题等配置数据
            let lang = localStorage.lang, theme = localStorage.theme;
            localStorage.clear();
            localStorage.lang = lang;
            localStorage.theme = theme;
        }
    }
};

export default user;
