
<div style="display: inline-block" class="message-content">
  <Dropdown trigger="click" placement="bottom-end">
    <div style="display: inline-block">
      <div class="header-right-icon header-action">
        <Badge :count="messageCount">
          <Icon type="md-notifications" :size="20" style="margin-top: 3px" />
        </Badge>
      </div>
    </div>
    <div slot="list" class="message-tip">
      <div class="title-content">
        <div class="title">{{ $t("Notifications") }}</div>
        <a
          class="mark"
          v-if="messageList && messageList.length > 0"
          @click="markAll"
          >{{ $t("markAll") }}</a
        >
        <a class="mark" v-else @click="getData">{{ $t("refresh") }}</a>
      </div>
      <div class="mes-list">
        <div
          v-if="messageList && messageList.length == 0"
          class="empty-message"
        >
          <Icon type="ios-mail-open-outline" size="70" color="#e4e4e4" />
          <p>{{ $t("noNewMes") }}</p>
        </div>
        <div v-else>
          <div
            v-for="(item, index) in messageList"
            :key="index"
            :class="{
              'mes-item': item.status == 0,
              'mes-item opacity': item.status == 1,
            }"
            @click="messageDetail(item.message)"
          >
            <div class="icon">
              <Avatar
                v-if="item.type == '通知'"
                style="background-color: #ff9900"
                icon="md-send"
                class="icon"
              />
              <Avatar
                v-else-if="item.type == '提醒'"
                style="background-color: #ed4014"
                icon="md-alarm"
                class="icon"
              />
              <Avatar
                v-else-if="item.type == '私信'"
                style="background-color: #87d068"
                icon="md-person"
                class="icon"
              />
              <Avatar
                v-else-if="item.type == '工作流'"
                style="background-color: #2db7f5"
                icon="md-briefcase"
                class="icon"
              />
              <Avatar
                v-else-if="item.type == '系统公告'"
                style="background-color: #2d8cf0"
                icon="md-mail"
                class="icon"
              />
              <Avatar v-else icon="ios-mail" class="icon" />
            </div>
            <div class="detail">
              <div class="mes-title">{{ item.message.title }}</div>
              <div class="mes-time">
                <Icon type="md-time" style="margin-right: 3px" /><Time
                  :time="item.createTime"
                />
              </div>
            </div>
          </div>
        </div>
        <Spin fix v-if="loading" />
      </div>
      <a class="show-more" @click="showMessage">
        <Icon
          type="ios-arrow-dropright-circle"
          size="16"
          style="margin: 0 5px 2px 0"
        />{{ $t("showMore") }}
      </a>
    </div>
  </Dropdown>
</div>
