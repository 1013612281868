
<div class="nav-app">
  <div
    :class="{
      'nav-item': currNav != item.name,
      'nav-item active': currNav == item.name,
    }"
    v-for="(item, i) in navList"
    :key="i"
    :name="item.name"
    @click="selectNav(item.name)"
  >
    <XIcon :type="item.icon" size="24"></XIcon>
    <div class="nav-title-wrap">
      <div class="nav-title">{{ item.title }}</div>
    </div>
  </div>
</div>
