
<i style="display: inline-block">
  <Icon
    v-if="checkIcon(type) == 'icon'"
    :type="type"
    :size="size"
    :color="color"
  />
  <Icon
    v-if="checkIcon(type) == 'iconfont'"
    :custom="type"
    :size="size"
    :color="color"
  />
  <img
    v-if="checkIcon(type) == 'link'"
    :src="type"
    :width="size + 'px'"
    :height="size + 'px'"
    style="vertical-align: -0.125em"
  />
</i>
