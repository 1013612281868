
<div>
  <Cascader
    v-model="select"
    :data="data"
    :load-data="loadData"
    transfer
    :disabled="disabled"
    :size="size"
    :placeholder="placeholder"
    :render-format="renderFormat"
    @on-change="handleChange"
  />
</div>
