// 解决编译问题，VUE可能不需要-----
// globalThis = global;
// globalThis.Promise = Promise;
// globalThis.WebSocket = WebSocket;
// -------------------------------
import {
    Centrifuge
} from "centrifuge";
import {
    getMsgCenterConnToken,
    getMsgCenterChannelToken,
} from "@/api/index";

export default class MessageCenterClient {
    constructor(app, debug) {
        this.app = app;
        this.debug = debug;
        this.endpoint = import.meta.env.VITE_MSG_SERVER;
        this.isConnected = false;
        this.channels = {};
        this.channelIdMap = {};
        this.client = undefined;
        this.uid = this.guid();
    }
    _OnReceiveMsg(ctx) {
        if (ctx) {
            console.log("OnReceiveMsg:", ctx);
            if (ctx.info.user == this.uid) {
                console.error("[MsgCenter] 已忽略来自自己的消息!");
            } else {
                this.channels[ctx.channel].onMsg(ctx.info, ctx.data);
            }
        }
    }
    Connect() {
        return new Promise((resolve, reject) => {
            let that = this;
            getMsgCenterConnToken(that.uid, 0).then((res) => {
                if (res && res.success) {
                    console.log(`[MsgCenter] 获取ConnectionToken: ${res.token}`);
                    if (!that.endpoint || (that.endpoint && that.endpoint.indexOf("ws:") < 0 && that.endpoint.indexOf("wss:") < 0)) {
                        reject("[MsgCenter] 参数错误, 请提供正确的endpoint地址(ws://ip:port), 当前endpoint: " + endpoint);
                    } else if (!that.isConnected) {
                        that.client = new Centrifuge(that.endpoint, {
                            token: res.token
                        });
                        that.client
                            .on("connected", function (ctx) {
                                that.isConnected = true;
                                console.log("[MsgCenter] 服务器连接成功");
                                resolve(that);
                            })
                            .on("disconnected", function (ctx) {
                                that.isConnected = false;
                                console.log("[MsgCenter] 服务器连接已经断开");
                                reject("[MsgCenter] 连接已经断开");
                            })
                            .connect();
                    }
                } else {
                    reject("[MsgCenter] 获取连接Token失败");
                }
            });
        });
    }
    OpenChannel(channel, onReceiveMsg, callback) {
        if (channel) {
            let that = this;
            if (that.isConnected) {
                getMsgCenterChannelToken(channel, that.uid, 0).then((res) => {
                    if (res && res.success) {
                        console.log(`[MsgCenter] 获取ChannelToken信息, channelId: ${res.channelId}, token: ${res.token}`);
                        that.channelIdMap[channel] = res.channelId;
                        let channelId = res.channelId;
                        if (typeof that.channels[channelId] === "undefined") {
                            let sub = that.client.newSubscription(channelId, {
                                token: res.token
                            });
                            sub.on("publication", (ctx) => {
                                that._OnReceiveMsg(ctx);
                            })
                                .on("subscribing", function (ctx) {
                                    console.log(`[MsgCenter][${ctx.channel}] 频道订阅中...`);
                                })
                                .on("subscribed", function (ctx) {
                                    console.log(`[MsgCenter][${ctx.channel}] 频道订阅成功`);
                                })
                                .on("unsubscribed", function (ctx) {
                                    console.log(`[MsgCenter][${ctx.channel}] 频道已取消订阅, 原因: ${ctx.reason}`);
                                })
                                .subscribe();
                            that.channels[channelId] = {
                                onMsg: onReceiveMsg,
                                sub: sub,
                            };
                        } else {
                            console.error("[MsgCenter] 请勿重复订阅频道(" + channelId + ")");
                        }
                        if (typeof callback === "function") {
                            callback();
                        }
                    } else {
                        reject("[MsgCenter] 获取频道订阅Token失败");
                    }
                });
            } else {
                console.error("[MsgCenter] 尚未连接消息服务器");
            }
        } else {
            console.error("[MsgCenter] OpenChannel方法的channel参数必须传入");
        }
    }
    CloseChannel(channelId) {
        if (this.isConnected) {
            this.client.unsubscribe(channelId);
            delete this.channels[channelId];
        } else {
            console.error("[MsgCenter] 尚未连接消息服务器");
        }
    }
    SendMsg(channelId, msgType, msg) {
        if (channelId) {
            if (this.isConnected) {
                console.log("[MsgCenter] 发送消息", {
                    channelId: channelId,
                    msgType: msgType,
                    data: msg,
                });
                this.client.publish(channelId, {
                    channelId: channelId,
                    msgType: msgType,
                    data: msg,
                });
            } else {
                console.error("[MsgCenter] 尚未连接服务器");
            }
        } else {
            console.error("[MsgCenter] SendMsg必须传入channelId参数");
        }
    }
    CreateAppChannelId(appName, channel) {
        // 生成频道Id
        return this.sha1(appName + "_" + channel);
    }
    encodeUTF8(s) {
        var i,
            r = [],
            c,
            x;
        for (i = 0; i < s.length; i++)
            if ((c = s.charCodeAt(i)) < 0x80) r.push(c);
            else if (c < 0x800) r.push(0xc0 + ((c >> 6) & 0x1f), 0x80 + (c & 0x3f));
            else {
                if ((x = c ^ 0xd800) >> 10 == 0)
                    //对四字节UTF-16转换为Unicode
                    (c = (x << 10) + (s.charCodeAt(++i) ^ 0xdc00) + 0x10000), r.push(0xf0 + ((c >> 18) & 0x7), 0x80 + ((c >> 12) & 0x3f));
                else r.push(0xe0 + ((c >> 12) & 0xf));
                r.push(0x80 + ((c >> 6) & 0x3f), 0x80 + (c & 0x3f));
            }
        return r;
    }

    // 字符串加密成 hex 字符串
    sha1(s) {
        var data = new Uint8Array(this.encodeUTF8(s));
        var i, j, t;
        var l = (((data.length + 8) >>> 6) << 4) + 16,
            s = new Uint8Array(l << 2);
        s.set(new Uint8Array(data.buffer)), (s = new Uint32Array(s.buffer));
        for (t = new DataView(s.buffer), i = 0; i < l; i++) s[i] = t.getUint32(i << 2);
        s[data.length >> 2] |= 0x80 << (24 - (data.length & 3) * 8);
        s[l - 1] = data.length << 3;
        var w = [],
            f = [
                function () {
                    return (m[1] & m[2]) | (~m[1] & m[3]);
                },
                function () {
                    return m[1] ^ m[2] ^ m[3];
                },
                function () {
                    return (m[1] & m[2]) | (m[1] & m[3]) | (m[2] & m[3]);
                },
                function () {
                    return m[1] ^ m[2] ^ m[3];
                },
            ],
            rol = function (n, c) {
                return (n << c) | (n >>> (32 - c));
            },
            k = [1518500249, 1859775393, -1894007588, -899497514],
            m = [1732584193, -271733879, null, null, -1009589776];
        (m[2] = ~m[0]), (m[3] = ~m[1]);
        for (i = 0; i < s.length; i += 16) {
            var o = m.slice(0);
            for (j = 0; j < 80; j++)
                (w[j] = j < 16 ? s[i + j] : rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1)),
                    (t = (rol(m[0], 5) + f[(j / 20) | 0]() + m[4] + w[j] + k[(j / 20) | 0]) | 0),
                    (m[1] = rol(m[1], 30)),
                    m.pop(),
                    m.unshift(t);
            for (j = 0; j < 5; j++) m[j] = (m[j] + o[j]) | 0;
        }
        t = new DataView(new Uint32Array(m).buffer);
        for (var i = 0; i < 5; i++) m[i] = t.getUint32(i << 2);

        var hex = Array.prototype.map
            .call(new Uint8Array(new Uint32Array(m).buffer), function (e) {
                return (e < 16 ? "0" : "") + e.toString(16);
            })
            .join("");
        return hex;
    }
    guid() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
}