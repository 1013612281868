
<div>
  <div
    :class="`search-menu search-menu-theme-${theme}`"
    v-if="type == 'input'"
  >
    <Select
      v-model="key"
      prefix="ios-search"
      clearable
      filterable
      :placeholder="$t('searchMenu')"
      @on-change="changeMenu"
      ref="select"
    >
      <Option v-for="(item, index) in list" :value="item.name" :key="index">{{
        item.title
      }}</Option>
    </Select>
    <Icon
      v-show="key"
      type="ios-close-circle"
      class="close-icon"
      @click="clear"
    />
  </div>
  <div class="search-dropdown" v-else>
    <Dropdown trigger="click" placement="bottom-start">
      <div style="display: inline-block">
        <div class="header-right-icon header-action">
          <Icon type="ios-search" :size="20" />
        </div>
      </div>
      <div slot="list" class="search-select">
        <Select
          v-model="key"
          prefix="ios-search"
          transfer
          clearable
          filterable
          :placeholder="$t('searchMenu')"
          @on-change="changeMenu"
          ref="select"
        >
          <Option
            v-for="(item, index) in list"
            :value="item.name"
            :key="index"
            >{{ item.title }}</Option
          >
        </Select>
      </div>
    </Dropdown>
  </div>
</div>
