
<div>
  <Row :gutter="gutterNum">
    <Col :span="span" v-if="show(0)">
      <Select
        ref="prov"
        v-model="currPro"
        @on-change="hasChange"
        :filterable="searchable"
        :placeholder="phHandled(0)"
        :size="size"
        :not-found-text="ndsHandled(0)"
        :disabled="disabled === undefined ? false : disHandled(0)"
        transfer
        style="width:100%"
      >
        <Option v-for="item in provList" :value="item" :key="item">{{ item }}</Option>
      </Select>
    </Col>
    <Col :span="span" v-if="show(1)">
      <Select
        ref="city"
        v-model="currCit"
        @on-change="hasChange"
        :filterable="searchable"
        :placeholder="phHandled(1)"
        :size="size"
        :not-found-text="ndsHandled(1)"
        :disabled="disabled === undefined ? false : disHandled(1)"
        transfer
        style="width:100%"
      >
        <Option v-for="item in cityList" :value="item" :key="item">{{ item }}</Option>
      </Select>
    </Col>
    <Col :span="span" v-if="show(2)">
      <Select
        ref="coun"
        v-model="currCou"
        @on-change="hasChange"
        :filterable="searchable"
        :placeholder="phHandled(2)"
        :size="size"
        :not-found-text="ndsHandled(2)"
        :disabled="disabled === undefined ? false : disHandled(2)"
        transfer
        style="width:100%"
      >
        <Option v-for="item in counList" :value="item" :key="item">{{ item }}</Option>
      </Select>
    </Col>
    <Col :span="span" v-if="show(3)">
      <Select
        ref="stre"
        v-model="currStr"
        @on-change="hasChange"
        :filterable="searchable"
        :placeholder="phHandled(3)"
        :size="size"
        :not-found-text="ndsHandled(3)"
        :disabled="disabled === undefined ? false : disHandled(3)"
        transfer
        style="width:100%"
      >
        <Option v-for="item in streList" :value="item" :key="item">{{ item }}</Option>
      </Select>
    </Col>
  </Row>
  <slot></slot>
</div>
