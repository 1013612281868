
<div>
  <Drawer
    title="修改密码"
    v-model="visible"
    width="450"
    draggable
    :mask-closable="false"
  >
    <div>
      <Form
        ref="form"
        :model="form"
        label-position="top"
        :rules="formValidate"
      >
        <FormItem label="原密码" prop="oldPass">
          <Input
            type="password"
            password
            v-model="form.oldPass"
            placeholder="请输入现在使用的密码"
          />
        </FormItem>
        <FormItem label="新密码" prop="newPass">
          <SetPassword
            placeholder="请输入新密码，长度为6-20个字符"
            v-model="form.newPass"
            @on-change="changeInputPass"
          />
        </FormItem>
        <FormItem label="确认新密码" prop="rePass">
          <Input
            type="password"
            password
            v-model="form.rePass"
            placeholder="请再次输入新密码"
          />
        </FormItem>
      </Form>
      <div class="drawer-footer br">
        <Button type="primary" :loading="submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="visible = false">取消</Button>
      </div>
    </div>
  </Drawer>
</div>
