
<div :class="`nav-beadcrumb-${theme}`">
  <Breadcrumb>
    <BreadcrumbItem
      v-for="item in currentPath"
      :to="item.path"
      :key="item.name"
      >{{ itemTitle(item) }}</BreadcrumbItem
    >
  </Breadcrumb>
</div>
