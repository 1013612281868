
<div
  :style="{ background: bgColor }"
  :class="`shrinkable-menu ${theme}-menu`"
>
  <slot name="top"></slot>
  <sidebar-menu
    v-show="!shrink"
    :theme="theme"
    :menu-list="menuList"
    :open-names="openNames"
    @on-change="handleChange"
  ></sidebar-menu>
  <sidebar-menu-shrink
    v-show="shrink"
    :theme="theme"
    :menu-list="menuList"
    :open-names="openNames"
    @on-change="handleChange"
  ></sidebar-menu-shrink>
</div>
