
<div
  ref="scrollCon"
  @DOMMouseScroll="handlescroll"
  @mousewheel="handlescroll"
  class="tags-scroll-content"
>
  <div
    ref="scrollBody"
    class="tags-scroll-body"
    :style="{ left: tagBodyLeft + 'px' }"
  >
    <transition-group name="taglist-moving-animation">
      <Tag
        type="dot"
        v-for="item in pageTagsList"
        ref="tagsPageOpened"
        :key="item.name"
        :name="item.name"
        @on-close="closePage"
        @click.native="linkTo(item)"
        :closable="item.name == 'home_index' ? false : true"
        :color="
          item.children
            ? item.children[0].name == currentPageName
              ? 'primary'
              : 'default'
            : item.name == currentPageName
            ? 'primary'
            : 'default'
        "
        >{{ itemTitle(item) }}</Tag
      >
    </transition-group>
  </div>
  <div class="close-tag-content">
    <Dropdown
      transfer
      trigger="hover"
      @on-click="handleTagsOption"
      placement="bottom-end"
      transfer-class-name="close-tag-dropdown"
    >
      <div class="icon-content">
        <Icon type="ios-arrow-down" size="16" />
      </div>
      <DropdownMenu slot="list">
        <DropdownItem name="clearAll">{{ $t("closeAll") }}</DropdownItem>
        <DropdownItem name="clearOthers">{{
          $t("closeOthers")
        }}</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>
</div>
