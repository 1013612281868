import axios from 'axios';
import {
    getStore,
    setStore
} from './storage';
import {
    router
} from '../router/index';
import {
    Message,
    Modal
} from 'view-design';
import Cookies from 'js-cookie';
import util from '@/libs/util'

const axios_instance = axios.create({
    baseURL: import.meta.env.VITE_BASE_API,
    timeout: 15000
});

// http request 拦截器
axios_instance.interceptors.request.use(config => {
    return config;
}, err => {
    Message.error('请求超时');
    return Promise.resolve(err);
});

// http response 拦截器
axios_instance.interceptors.response.use(response => {
    const data = response.data;
    // 根据返回的code值来做不同的处理(和后端约定), 注意，这里不是处理Http Status Code的地方！
    switch (response.status) {
        case 401:
            // 未登录 清除已登录状态
            Cookies.set('userInfo', '');
            setStore('userInfo', '');
            setStore('accessToken', '');
            if (router.history.current.name != "login") {
                if (data.message !== null) {
                    Message.error(data.message);
                } else {
                    Message.error("未知错误，请重新登录");
                }
                router.push('/login');
            }
            break;
        case 403:
            // 没有权限
            if (data.message !== null) {
                Message.error(data.message);
            } else {
                Message.error("未知错误");
            }
            break;
        case 500:
            // 错误
            if (data.message !== null) {
                Message.error(data.message);
            } else {
                Message.error("未知错误");
            }
            break;
        default:
            return data;
    }
    return data;
}, (err) => {
    // 返回状态码不为200时候的错误处理
    if (err.request && err.request.status == 401) {
        if (router.history.current.name != "login" && router.history.current.name != null) {
            // 未登录 清除已登录状态
            Cookies.set('userInfo', '');
            setStore('accessToken', '');
            setStore('userInfo', '');
            Modal.error({
                title: "错误",
                content: "登陆已失效，请重新登录",
                okText: "前往登录",
                onOk() {
                    router.push('/login');
                }
            })
        } else {
            return Promise.resolve(err);
        }
    } else {
        Message.error(err.toString());
        return Promise.resolve(err);
    }
});

const setDefaultParam = (url, params) => {
    let myDepartmentIds = getStore("myDepartmentIds");
    let departmentId = getStore("departmentId");
    if (params) {
        if (url.indexOf("getByCondition") > -1 || typeof (params.sort) == "object") {
            // 查询
            if (typeof (params.DepartmentId) == "undefined" || (typeof (params.DepartmentId) == "object") && params.DepartmentId.value == "") {
                params.DepartmentId = {
                    comparator: "in",
                    value: myDepartmentIds,
                }
            }
        } else {
            // 更新
            if (url != '/manage/user/add' && url != '/manage/user/edit') {
                // 忽略用户中心编辑接口
                if (typeof (params.DepartmentId) == "undefined") {
                    params.DepartmentId = departmentId;
                }
            }
        }
    }
    return params;
}

export const getRequest = (url, params) => {
    let accessToken = getStore('accessToken');
    params = setDefaultParam(url, params);
    return axios_instance.get(url, {
        params: params,
        headers: {
            'accessToken': accessToken
        }
    });
};

export const postRequest = (url, params) => {
    let accessToken = getStore("accessToken");
    params = setDefaultParam(url, params);
    return axios_instance.post(url, params, {
        transformRequest: [function (data) {
            let ret = '';
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            ret = ret.substring(0, ret.length - 1);
            if (
                import.meta.env.VITE_USE_WAF == "true") {
                let encryptedData = util.Encrypt(JSON.stringify({
                    r: util.NewHexId(),
                    d: ret
                }));
                return encryptedData;
            } else {
                return ret;
            }
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'accessToken': accessToken
        }
    });
};


export const exportRequest = (url, params) => {
    let accessToken = getStore("accessToken");
    params = setDefaultParam(url, params);
    return axios_instance.post(url, params, {
        transformRequest: [function (data) {
            let ret = '';
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            ret = ret.substring(0, ret.length - 1);
            return ret;
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'accessToken': accessToken
        }
    });
};


export const putRequest = (url, params) => {
    let accessToken = getStore("accessToken");
    params = setDefaultParam(url, params);
    return axios_instance.put(url, params, {
        transformRequest: [function (data) {
            let ret = '';
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            ret = ret.substring(0, ret.length - 1);
            if (
                import.meta.env.VITE_USE_WAF == "true") {
                let encryptedData = util.Encrypt(JSON.stringify({
                    r: util.NewHexId(),
                    d: ret
                }));
                return encryptedData;
            } else {
                return ret;
            }
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'accessToken': accessToken
        }
    });
};

export const postBodyRequest = (url, params) => {
    let accessToken = getStore('accessToken');
    params = setDefaultParam(url, params);
    if (
        import.meta.env.VITE_USE_WAF == "true") {
        let encryptedData = util.Encrypt(JSON.stringify({
            r: util.NewHexId(),
            d: JSON.stringify(params)
        }));
        return axios_instance.post(url, encryptedData, {
            headers: {
                'accessToken': accessToken
            }
        });
    } else {
        return axios_instance.post(url, params, {
            headers: {
                'accessToken': accessToken
            }
        });
    }
};
/**
 * 无需token验证的GET请求 避免旧token过期导致请求失败
 * @param {*} url 
 * @param {*} params 
 */
export const getNoAuthRequest = (url, params) => {
    params = setDefaultParam(url, params);
    return axios_instance.get(url, {
        params: params
    });
};

export const postNoAuthRequest = (url, params) => {
    params = setDefaultParam(url, params);
    return axios_instance.post(url, params, {
        transformRequest: [function (data) {
            let ret = '';
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            ret = ret.substring(0, ret.length - 1);
            if (
                import.meta.env.VITE_USE_WAF == "true") {
                let toEnc = JSON.stringify({
                    r: util.NewHexId(),
                    d: ret
                });
                let encryptedData = util.Encrypt(toEnc);
                return encryptedData;
            } else {
                return ret;
            }
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

export const postBodyNoAuthRequest = (url, params) => {
    params = setDefaultParam(url, params);
    if (
        import.meta.env.VITE_USE_WAF == "true") {
        let encryptedData = util.Encrypt(JSON.stringify({
            r: util.NewHexId(),
            d: JSON.stringify(params)
        }));
        return axios_instance.post(url, encryptedData);
    } else {
        return axios_instance.post(url, params);
    }
};


export const uploadFileRequest = (url, key, file) => {
    if (!key) {
        key = "file"
    }
    const data = new FormData();
    data.append(key, file);
    return axios_instance.post(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
};