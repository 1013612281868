
<div style="display: inline-block">
  <Dropdown @on-click="selectNav" :placement="placement">
    <div class="header-right-icon header-action" v-if="currType == 'icon'">
      <XIcon type="ios-apps" :size="20"></XIcon>
    </div>
    <div
      :class="`single-nav header-action single-nav-theme-${theme} `"
      v-else
    >
      {{ currNavTitle }}
      <XIcon type="ios-arrow-down" class="single-nav-dropdown-icon"></XIcon>
    </div>
    <DropdownMenu slot="list" v-if="type == 'list'">
      <DropdownItem
        v-for="(item, i) in navList"
        :key="i"
        :name="item.name"
        :selected="currNav == item.name"
      >
        <div>
          <XIcon
            :type="item.icon"
            :size="14"
            style="margin: 0 10px 0 0"
          ></XIcon>
          {{ item.title }}
        </div>
      </DropdownItem>
    </DropdownMenu>
    <div slot="list" v-else>
      <apps :currNav="currNav" :navList="navList" @on-click="selectNav" />
    </div>
  </Dropdown>
</div>
