
<div style="display: inline-block">
  <Menu
    :class="'ivu-menu-'+ theme"
    mode="horizontal"
    :active-name="currNav"
    @on-select="selectNav"
  >
    <MenuItem
      v-for="(item, i) in navList.slice(0, sliceNum)"
      :key="i"
      :name="item.name"
    >
      <XIcon :type="item.icon" v-if="showIcon" />
      {{ item.title }}
    </MenuItem>
    <Submenu name="sub" v-if="navList.length > sliceNum">
      <template slot="title">更多</template>
      <MenuItem
        v-for="(item, i) in navList.slice(sliceNum, navList.length)"
        :key="i"
        :name="item.name"
      >
        <XIcon :type="item.icon" v-if="showIcon" />
        {{ item.title }}
      </MenuItem>
    </Submenu>
  </Menu>
</div>
